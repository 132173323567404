import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";

export default function AdminLogin() {
  const [message, setMessage] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    setIsLoading(true);
    const formdata = new FormData();
    formdata.append("email", data.email);
    formdata.append("password", data.password);

    axios
      .post("/api/admin/login", formdata)
      .then((res) => {
        console.log(res);
        if (res.data.status === 200) {
          reset();
          localStorage.setItem("token", res.data.access_token);
          localStorage.setItem("role", "admin");
          window.location.reload();
        }
        if (res.data.message) {
          setMessage({
            type: res.data.status,
            message: res.data.message,
          });
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (localStorage.getItem("token") && localStorage.getItem("role") === "admin") {
      window.location.href = "/admin/booking";
    }
  }, []);

  return (
    <div>
      <div className="mx-5 md:w-3/6 rounded md:mx-auto bg-slate-50 border py-10 my-20 flex flex-col justify-center items-center">
        <h1 className="text-2xl">Admin Login</h1>
        <form className="w-full lg:w-5/6 py-5 px-5" onSubmit={handleSubmit(onSubmit)}>
          {message && (
            <p
              className={`px-2 py-3 rounded text-white text-center ${
                message.type === 200 ? "bg-green-600" : "bg-red-600"
              }`}
            >
              {message.message}
            </p>
          )}
          <div className="mb-6">
            <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Your email
            </label>
            <input
              type="email"
              id="email"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="name@flowbite.com"
              {...register("email", { required: true })}
            />
            {errors.email && <span className="text-red-500 text-sm">This field is required</span>}
          </div>
          <div className="mb-6">
            <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Your password
            </label>
            <input
              type="password"
              id="password"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              {...register("password", { required: true })}
            />
            {errors.password && <span className="text-red-500 text-sm">This field is required</span>}
          </div>
          <button
            type="submit"
            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          >
            Login
          </button>
        </form>
      </div>
    </div>
  );
}
