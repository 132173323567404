import { Routes, Route } from "react-router-dom";
import axios from "axios";
import ContactUs from "./pages/ContactUs";
import Home from "./pages/Home";
import Tours from "./pages/Tours";
import Login from "./pages/Login";
import Register from "./pages/Register";
import AdminLogin from "./pages/admin/AdminLogin";
import BookingList from "./pages/admin/BookingList";
import Trips from "./pages/admin/trips";
import Periods from "./pages/admin/periods";
import Packages from "./pages/admin/packages";
import Users from "./pages/admin/users";
import AddTrip from "./pages/admin/trips/Add";
import AddPeriod from "./pages/admin/periods/Add";
import AddPackage from "./pages/admin/packages/Add";
import AddUser from "./pages/admin/users/Add";
import EditTrip from "./pages/admin/trips/Edit";
import EditPeriod from "./pages/admin/periods/Edit";
import EditPackage from "./pages/admin/packages/Edit";
import EditUser from "./pages/admin/users/Edit";
import About from "./pages/About";
import OurAdvantages from "./pages/OurAdvantages";
function App() {
  axios.defaults.baseURL = "https://jana.tours/janatours-jana-tours/public/";
  if (localStorage.getItem("token"))
    axios.defaults.headers["auth-token"] = localStorage.getItem("token");

  return (
    <div className="App ">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/about" element={<About />} />
        <Route path="/advantages" element={<OurAdvantages />} />
        <Route path="/contact" element={<ContactUs />} />
        {/* <Route path="/tour/:id" element={<Tour />} /> */}
        <Route path="/tours" element={<Tours />}>
          <Route path="/tours/:period" element={<Tours />} />
        </Route>
        <Route path="/admin">
          <Route path="/admin" element={<AdminLogin />} />
          <Route path="/admin/login" element={<AdminLogin />} />
          <Route path="/admin/booking" element={<BookingList />} />
          <Route path="/admin/trips" element={<Trips />} />
          <Route path="/admin/trips/add" element={<AddTrip />} />
          <Route path="/admin/trips/edit/:id" element={<EditTrip />} />
          <Route path="/admin/periods" element={<Periods />} />
          <Route path="/admin/periods/add" element={<AddPeriod />} />
          <Route path="/admin/periods/edit/:id" element={<EditPeriod />} />
          <Route path="/admin/packages" element={<Packages />} />
          <Route path="/admin/packages/add" element={<AddPackage />} />
          <Route path="/admin/packages/edit/:id" element={<EditPackage />} />
          <Route path="/admin/users" element={<Users />} />
          <Route path="/admin/users/add" element={<AddUser />} />
          <Route path="/admin/users/edit/:id" element={<EditUser />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
