import React from "react";
import { BsFillPersonFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import { HiMenuAlt1 } from "react-icons/hi";
import { FaBars } from "react-icons/fa";

export default function Navbar() {
  const [mobileMenu, setMobileMenu] = React.useState(false);
  const ref = React.useRef(null);

  React.useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setMobileMenu(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [mobileMenu]);

  return (
    <nav className="navbar z-20 text-lg bg-[#fff] sticky top-0 shadow-md">
      <div className="nav-col1">
        {/* <a href="/"> */}
        <img className="nav-logo" src="https://via.placeholder.com/100" alt="jana-tours" />
        {/* </a> */}
        <div className="nav-ham mx-5">
          <FaBars className="text-2xl cursor-pointer hover:text-[#e8604c]" onClick={() => setMobileMenu(!mobileMenu)} />
        </div>
      </div>
      <div className={`nav-col2 ${mobileMenu ? "open" : ""}`} ref={ref}>
        <div className="hover:text-[#e8604c]">
          <Link to="/">Home</Link>
        </div>
        <div className="hover:text-[#e8604c]">
          <Link to="/about">About</Link>
        </div>
        <div className="hover:text-[#e8604c]">
          <Link to="/advantages">Advantages</Link>
        </div>
        <div className="hover:text-[#e8604c]">
          <Link to="/tours">Tours</Link>
        </div>
        <div className="hover:text-[#e8604c]">
          <Link to="/contact">Contact US</Link>
        </div>
      </div>
      <div className="nav-col3">
        <div>
          <Link to="/login">
            {" "}
            <BsFillPersonFill className="text-2xl hover:text-[#e8604c] hover:cursor-pointer" />
          </Link>
        </div>
      </div>
    </nav>
  );
}
