import React from "react";
import MainLayout from "../layout/MainLayout";

export default function YouAreLoggedIn() {
  return (
    <MainLayout>
      <div className="flex flex-col justify-center items-center gap-8 py-20">
        <h2 className="text-4xl">You are logged in</h2>
        <a
          className="text-[#e8604c] underline text-lg"
          onClick={() => {
            localStorage.removeItem("token");
            window.location.reload();
          }}
        >
          Logout
        </a>
      </div>
    </MainLayout>
  );
}
