import React from "react";
import { BsFacebook, BsFillTelephoneFill, BsInstagram, BsMailbox, BsPinFill } from "react-icons/bs";

export default function Footer() {
  return (
    <footer className="py-10  px-16 mt-5 flex  justify-between bg-[#313041]	flex-col md:flex-row text-[#fff]">
      <section className="w-full md:w-1/5">
        <img src="https://via.placeholder.com/150" />
      </section>

      <section className="w-full pt-4 md:pt-0 md:w-2/5">
        <p className="py-3">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Quaerat totam nesciunt atque ad aperiam.
        </p>
        <div className="border-t-2 border-[#484563]	p-2 hover:text-[#e8604c] hover:cursor-pointer">
          <BsFillTelephoneFill className="inline text-2xl " /> 92 666 999 0000
        </div>
        <div className="p-2 hover:text-[#e8604c] hover:cursor-pointer">
          <BsMailbox className="inline text-2xl hover:text-[#e8604c] hover:cursor-pointer" /> info@jana-tours.com
        </div>
        <div className="p-2 hover:text-[#e8604c] hover:cursor-pointer">
          <BsPinFill className="inline text-2xl hover:text-[#e8604c] hover:cursor-pointer" /> 666 road, broklyn street
          new york
        </div>
      </section>
      <section className="w-full pt-6 md:pt-0 md:w-1/5">
        <h6 className="text-xl">Follow US</h6>
        <div className="p-2">
          <BsFacebook className="text-2xl hover:text-[#e8604c] hover:cursor-pointer" />
        </div>
        <div className="p-2">
          <BsInstagram className="text-2xl hover:text-[#e8604c] hover:cursor-pointer" />
        </div>
      </section>
    </footer>
  );
}
