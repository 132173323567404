import React from "react";
import { Link } from "react-router-dom";
import Modal from "react-modal";
import BookModal from "../BookModal";
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    zIndex: "2000",
  },
};

export default function LatestTripCard(props) {
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }

  return (
    <>
      <div className="rounded-md mb-5 overflow-hidden	break-inside-avoid	border border-[#ebe6de] relative">
        {/* <Link to={`/tour/${props.id}`}> */}
        <img src={props.image} />
        <div className="py-1 px-2 flex justify-between items-center">
          <div>
            <h3 className="font-semibold">{props.title}</h3>
            <p>{props.description}</p>
          </div>
          <div>
            {localStorage.getItem("token") ? (
              <button className="bg-[#e8604c] hover:bg-slate-800 text-white rounded-md px-4 py-2" onClick={openModal}>
                Book Now
              </button>
            ) : (
              <a href="/login" className="text-[#e8604c] underline">
                Login to book
              </a>
            )}
          </div>
        </div>
        <span className="absolute top-2 right-2 bg-slate-200 px-2 py-1 rounded text-sm">
          {new Date(props.date).toDateString()}
        </span>
        {/* </Link> */}
      </div>
      <BookModal id={props.id} title={`Book ${props.title} Tour`} isOpen={modalIsOpen} closeModal={closeModal} />
    </>
  );
}
