import React from "react";
import AdminLayout from "../AdminLayout";
import { useForm } from "react-hook-form";
import axios from "axios";
import { useEffect } from "react";

export default function AddPackage() {
  const [message, setMessage] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    setIsLoading(true);
    const formdata = new FormData();
    formdata.append("description", data.description);

    axios
      .post("/api/admin/packages/store", formdata)
      .then((res) => {
        console.log(res);
        if (res.data.status === 200) {
          reset();
          window.location.href = "/admin/packages";
        }
        if (res.data.message) {
          setMessage({
            type: res.data.status,
            message: res.data.message,
          });
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  return (
    <AdminLayout>
      <h1 className="text-2xl">Add Package</h1>
      <form className="my-5" onSubmit={handleSubmit(onSubmit)}>
        {message && (
          <p
            className={`px-2 py-3 rounded text-white text-center ${
              message.type === 200 ? "bg-green-600" : "bg-red-600"
            }`}
          >
            {message.message}
          </p>
        )}
        <div className="mb-6">
          <label htmlFor="description" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
            Description
          </label>
          <input
            type="text"
            id="description"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            {...register("description", { required: true })}
          />
          {errors.description && <span className="text-red-500 text-sm">This field is required</span>}
        </div>

        <button
          type="submit"
          className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          disabled={isLoading}
        >
          {isLoading ? "Loading..." : "Add Package"}
        </button>
      </form>
    </AdminLayout>
  );
}
