import React from "react";
import Sidebar from "../../components/Sidebar";

export default function AdminLayout({ children }) {
  return (
    <div>
      <Sidebar />
      <div className="md:ml-64 px-5 py-5">{children}</div>
    </div>
  );
}
