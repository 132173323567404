import React, { useState } from "react";
import AnimatedNumbers from "react-animated-numbers";

export default function QuickSearch() {
  const [day, setDay] = useState("DAYS");
  const [searchForm, setSearchForm] = useState(1);
  function handleSearchChange(e) {
    if (e.target.value === "") setDay("DAYS");
    else if (e.target.value > 1) setDay("DAYS");
    else setDay("DAY");
    setSearchForm(e.target.value);
  }
  function handleSearchSubmit(e) {
    e.preventDefault();
    window.location.href = `/tours/${searchForm}`;
  }
  return (
    <section className="quick-search">
      <form onSubmit={handleSearchSubmit}>
        <div className="py-5">
          <label className="text-3xl font-medium py-5 block mb-5">You are staying for?</label>
          <div className="quick-search-container flex-col items-center md:flex-row gap-3 md:gap-0">
            <input
              type={"number"}
              placeholder="# OF DAYS"
              min={1}
              onChange={handleSearchChange}
              value={searchForm}
              className="border py-4 px-2 focus:outline-none focus:border-[#e8604c] rounded w-full"
            />
            <p className="md:px-4 hidden md:inline-block">DAYS</p>
            <button className="bg-[#e8604c] hover:bg-slate-800 text-white rounded-md px-4 py-4 md:w-44 w-full">
              Search
            </button>
          </div>
        </div>
      </form>
    </section>
  );
}
