import React from "react";
import MainLayout from "../layout/MainLayout";

export default function About() {
  return (
    <MainLayout>
      <div className="container py-10">
        <h1 className="text-3xl text-center">About</h1>
        <p className="my-4 leading-9">
          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Minus quos eius explicabo unde adipisci id nostrum,
          quasi vitae nemo itaque saepe? Repudiandae aut aliquid nostrum tenetur accusamus mollitia magni in eligendi
          dolorem, pariatur earum veritatis nulla voluptatum provident atque rerum saepe beatae maiores et quibusdam.
          Laborum distinctio ullam officiis. Voluptatum quisquam iure, vero dolorum eum fugiat sunt pariatur quo vel qui
          dignissimos, veniam ad sapiente sint reprehenderit aperiam. Dolorum quos odio modi blanditiis dicta
          voluptatibus nulla cupiditate iusto minus repellendus nisi totam ea quia quis maiores obcaecati fugit, eaque
          laborum nam ullam aperiam. Voluptatum et quo pariatur labore molestias blanditiis!
        </p>
        <img src="https://picsum.photos/800/600" alt="random" className="mx-auto my-3" />
        <p className="my-4 leading-9">
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ea ad beatae rerum laudantium laborum cupiditate
          molestiae cumque exercitationem. Numquam mollitia nesciunt impedit eligendi quas obcaecati, quia, iste nam
          voluptatum eveniet sed, at ipsa aliquid ipsam quis incidunt dolore eos modi distinctio dolorum quibusdam
          deserunt adipisci voluptates. Corrupti a odit ullam?
        </p>
      </div>
    </MainLayout>
  );
}
