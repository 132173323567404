import React from "react";
import AdminLayout from "../AdminLayout";
import { useForm } from "react-hook-form";
import axios from "axios";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

export default function EditTrip() {
  const { id } = useParams();
  const [message, setMessage] = React.useState(null);
  const [periods, setPeriods] = React.useState([]);
  const [packages, setPackages] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    setIsLoading(true);
    const formdata = new FormData();
    data.title && formdata.append("title", data.title);
    data.description && formdata.append("description", data.description);
    data.period && formdata.append("period_id", data.period);
    data.package && formdata.append("package_id", data.package);
    data.date && formdata.append("date", data.date);
    data.banner.length && data.banner[0] && formdata.append("banner", data.banner[0]);

    axios
      .post(`/api/admin/trips/update/${id}`, formdata)
      .then((res) => {
        console.log(res);
        if (res.data.status === 200) {
          reset();
          window.location.href = "/admin/trips";
        }
        if (res.data.message) {
          setMessage({
            type: res.data.status,
            message: res.data.message,
          });
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (!id) return;
    axios.get(`/api/admin/periods/index`).then((res) => {
      setPeriods(res.data.packages);
    });
    axios.get(`/api/admin/packages/index`).then((res) => {
      setPackages(res.data.packages);
    });
    axios
      .get(`/api/admin/trips/edit/${id}`)
      .then((res) => {
        console.log(res);
        setValue("title", res.data.title);
        setValue("package", res.data.package_id);
        setValue("period", res.data.period_id);
        setValue("date", res.data.date);
        setValue("description", res.data.description);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [id]);

  return (
    <AdminLayout>
      <h1 className="text-2xl">Edit Trip</h1>
      <form className="my-5" onSubmit={handleSubmit(onSubmit)}>
        {message && (
          <p
            className={`px-2 py-3 rounded text-white text-center ${
              message.type === 200 ? "bg-green-600" : "bg-red-600"
            }`}
          >
            {message.message}
          </p>
        )}
        <div className="mb-6">
          <label htmlFor="banner" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
            Upload New Banner
          </label>
          <input
            type="file"
            id="banner"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            {...register("banner", { required: true })}
          />
          {errors.banner && <span className="text-red-500 text-sm">This field is required</span>}
        </div>
        <div className="mb-6">
          <label htmlFor="title" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
            Title
          </label>
          <input
            type="text"
            id="title"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            {...register("title", { required: true })}
          />
          {errors.title && <span className="text-red-500 text-sm">This field is required</span>}
        </div>

        <div className="mb-6">
          <label htmlFor="package" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
            Package
          </label>
          <select
            id="package"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            {...register("package", { required: true })}
          >
            <option selected>Choose a country</option>
            {packages.map((item) => (
              <option value={item.id}>{item.description}</option>
            ))}
          </select>
          {errors.package && <span className="text-red-500 text-sm">This field is required</span>}
        </div>

        <div className="mb-6">
          <label htmlFor="period" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
            Period
          </label>
          <select
            id="period"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            {...register("period", { required: true })}
          >
            <option selected>Choose a country</option>
            {periods.map((item) => (
              <option value={item.id}>{item.period}</option>
            ))}
          </select>
          {errors.period && <span className="text-red-500 text-sm">This field is required</span>}
        </div>

        <div className="mb-6">
          <label htmlFor="description" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
            Description
          </label>
          <input
            type="text"
            id="description"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            {...register("description", { required: true })}
          />
          {errors.description && <span className="text-red-500 text-sm">This field is required</span>}
        </div>

        <div className="mb-6">
          <label htmlFor="date" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
            Date
          </label>
          <input
            type="date"
            id="date"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            value={new Date().toISOString().split("T")[0]}
            {...register("date", { required: true })}
          />
          {errors.date && <span className="text-red-500 text-sm">This field is required</span>}
        </div>

        <button
          type="submit"
          className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          disabled={isLoading}
        >
          {isLoading ? "Loading..." : "Save Trip"}
        </button>
      </form>
    </AdminLayout>
  );
}
