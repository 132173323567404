import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import AdminLayout from "./AdminLayout";

export default function BookingList() {
  const [data, setData] = useState([]);

  useEffect(() => {
    axios.get("/api/admin/trips/bookings").then((res) => {
      if (!res.data.packages) {
        localStorage.removeItem("token");
        localStorage.removeItem("role");
        window.location.href = "/admin/login";
      }
      setData(res.data.packages.data);
    });
  }, []);
  return (
    <AdminLayout>
      <h1 className="text-2xl">Booking List</h1>

      <div className="overflow-x-auto relative mt-5 sm:rounded-lg">
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="py-3 px-6">
                id
              </th>
              <th scope="col" className="py-3 px-6">
                email
              </th>
              <th scope="col" className="py-3 px-6">
                phone
              </th>
              <th scope="col" className="py-3 px-6">
                Number of adults
              </th>
              <th scope="col" className="py-3 px-6">
                Number of childrens
              </th>
              <th scope="col" className="py-3 px-6">
                Number of infants
              </th>
              <th scope="col" className="py-3 px-6">
                note
              </th>
              {/* <th scope="col" className="py-3 px-6">
                <span className="sr-only">Edit</span>
              </th> */}
            </tr>
          </thead>
          <tbody>
            {data.map((el) => (
              <tr
                key={el.id}
                className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
              >
                <th scope="row" className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                  {el.id}
                </th>
                <td className="py-4 px-6">{el.email}</td>
                <td className="py-4 px-6">{el.phone}</td>
                <td className="py-4 px-6">{el.number_of_adults}</td>
                <td className="py-4 px-6">{el.number_of_childrens}</td>
                <td className="py-4 px-6">{el.number_of_infants}</td>
                <td className="py-4 px-6">{el.note}</td>
                {/* <td className="py-4 px-6 text-right">
                  <a href="#" className="font-medium text-blue-600 dark:text-blue-500 hover:underline">
                    Edit
                  </a>
                </td> */}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </AdminLayout>
  );
}
