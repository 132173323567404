import React from "react";
import MainLayout from "../layout/MainLayout";

export default function ContactUs() {
  return (
    <MainLayout>
      <div className="container flex flex-col items-center gap-3 py-20">
        <h1 className="text-3xl">Contact Us</h1>
        <form className="w-full flex flex-col gap-3 md:w-3/6">
          <div className="flex flex-col gap-3 w-full">
            <label htmlFor="name">Name</label>
            <input type="text" name="name" id="name" className="border border-gray-300 rounded-md px-3 py-2" />
          </div>
          <div className="flex flex-col gap-3 w-full">
            <label htmlFor="email">Email</label>
            <input type="email" name="email" id="email" className="border border-gray-300 rounded-md px-3 py-2" />
          </div>
          <div className="flex flex-col gap-3 w-full">
            <label htmlFor="message">Message</label>
            <textarea name="message" id="message" rows={10} className="border border-gray-300 rounded-md px-3 py-2" />
          </div>
          <button className="bg-[#e8604c] text-white py-2 px-4 rounded-md hover:bg-[#ce5744]">Submit</button>
        </form>
      </div>
    </MainLayout>
  );
}
