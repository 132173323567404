import React from "react";
import Carousel from "../components/home/Carousel";
import Gallery from "../components/home/Gallery";
import LatestTrips from "../components/home/LatestTrips";
import QuickSearch from "../components/home/QuickSearch";

import MainLayout from "../layout/MainLayout";

export default function Home() {
  return (
    <MainLayout>
      <Carousel />
      <QuickSearch />
      <LatestTrips />
      <Gallery />
    </MainLayout>
  );
}
