import React from "react";
import loading from "../../assets/loading.svg";
import { BiLoaderAlt } from "react-icons/bi";

export default function Loader() {
  return (
    <div className="flex items-center justify-center py-20">
      <BiLoaderAlt className="text-6xl animate-spin	text-[#e8604c]" />
    </div>
  );
}
