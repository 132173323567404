import axios from "axios";
import React, { useEffect, useState } from "react";
import AdminLayout from "../AdminLayout";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";

export default function Trips() {
  const [data, setData] = useState([]);

  useEffect(() => {
    axios.get(`/api/admin/trips/index`).then((res) => {
      console.log(res);
      if (!res.data.trips) {
        localStorage.removeItem("token");
        localStorage.removeItem("role");
        window.location.href = "/admin/login";
      }
      setData(res.data.trips);
    });
  }, []);

  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await axios.get(`/api/admin/trips/delete/${id}`);
        Swal.fire("Deleted!", "Your record has been deleted.", "success");
      }
    });
  };

  return (
    <AdminLayout>
      <header className="flex justify-between items-center">
        <h1 className="text-2xl">Trips List</h1>
        <a href="/admin/trips/add" className="bg-blue-500 hover:bg-blue-700 text-white py-1 px-4 rounded">
          Create
        </a>
      </header>

      <div className="overflow-x-auto relative mt-5 sm:rounded-lg">
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="py-3 px-6">
                id
              </th>
              <th scope="col" className="py-3 px-6">
                title
              </th>
              <th scope="col" className="py-3 px-6">
                description
              </th>
              <th scope="col" className="py-3 px-6">
                date
              </th>
              <th scope="col" className="py-3 px-6">
                <span className="sr-only">Edit/Delete</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {data.map((el) => (
              <tr
                key={el.id}
                className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
              >
                <th scope="row" className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                  {el.id}
                </th>
                <td className="py-4 px-6">{el.title}</td>
                <td className="py-4 px-6">{el.description}</td>
                <td className="py-4 px-6">{el.date}</td>
                <td className="py-4 px-6 text-right flex gap-2">
                  <Link to={`edit/${el.id}`} className="font-medium text-blue-600 dark:text-blue-500 hover:underline">
                    Edit
                  </Link>
                  <button
                    onClick={() => handleDelete(el.id)}
                    className="font-medium text-red-600 dark:text-red-500 hover:underline"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </AdminLayout>
  );
}
