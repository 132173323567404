import React from "react";
import MainLayout from "../layout/MainLayout";
import { useForm } from "react-hook-form";
import axios from "axios";
import YouAreLoggedIn from "./YouAreLoggedIn";

export default function Register() {
  const [message, setMessage] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    setIsLoading(true);
    const formdata = new FormData();
    formdata.append("email", data.email);
    formdata.append("password", data.password);
    formdata.append("name", data.name);
    formdata.append("password_confirmation", data.confirm_password);

    axios
      .post("/api/users/register", formdata)
      .then((res) => {
        console.log(res);
        if (res.data.status === "success") {
          reset();
          localStorage.setItem("token", res.data.authorisation.token);
          window.location.reload();
        }
        if (res.data.message) {
          setMessage({
            type: res.data.status,
            message: res.data.message,
          });
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  if (localStorage.getItem("token")) {
    return <YouAreLoggedIn />;
  }

  return (
    <MainLayout>
      <div className="flex flex-col items-center gap-3 py-10">
        <h1 className="text-3xl">Register</h1>

        <form className="my-5 flex flex-col gap-5" onSubmit={handleSubmit(onSubmit)}>
          {message && (
            <p
              className={`px-2 py-3 rounded text-white text-center ${
                message.type === "success" ? "bg-green-600" : "bg-red-600"
              }`}
            >
              {message.message}
            </p>
          )}
          <div className="flex flex-col gap-2 w-96">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              name="name"
              id="name"
              className="border py-2 px-1 w-full"
              {...register("name", { required: true })}
            />
            {errors.name && <span className="text-red-500 text-sm">This field is required</span>}
          </div>
          <div className="flex flex-col gap-2 w-96">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              name="email"
              id="email"
              className="border py-2 px-1 w-full"
              {...register("email", { required: true })}
            />
            {errors.email && <span className="text-red-500 text-sm">This field is required</span>}
          </div>
          <div className="flex flex-col gap-2 w-96">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              name="password"
              id="password"
              className="border py-2 px-1 w-full"
              {...register("password", { required: true })}
            />
            {errors.password && <span className="text-red-500 text-sm">This field is required</span>}
          </div>
          <div className="flex flex-col gap-2 w-96">
            <label htmlFor="confirm_password">Confirm Password</label>
            <input
              type="password"
              name="confirm_password"
              id="confirm_password"
              className="border py-2 px-1 w-full"
              {...register("confirm_password", { required: true })}
            />
            {errors.confirm_password && <span className="text-red-500 text-sm">This field is required</span>}
          </div>
          <button className="bg-[#e8604c] text-white py-2 px-4 rounded-md" disabled={isLoading}>
            {isLoading ? "Loading..." : "Register"}
          </button>
        </form>
        <p>
          Have an account?{" "}
          <a href="/login" className="text-[#e8604c] hover:underline">
            Login
          </a>
        </p>
      </div>
    </MainLayout>
  );
}
