import React from "react";
import MainLayout from "../layout/MainLayout";

export default function OurAdvantages() {
  return (
    <MainLayout>
      <div className="container py-10">
        <h1 className="text-3xl text-center">Our Advantages</h1>
        <div className="my-5 grid grid-cols-1 gap-5 md:grid-cols-2">
          <div className="flex gap-3 items-center">
            <div className="p-3 bg-slate-50 border rounded-full">
              <img
                src="https://cdn-icons-png.flaticon.com/512/686/686308.png"
                alt="random"
                className="w-10 h-10 object-fit flex-shrink-0"
              />
            </div>
            <div className="my-4">
              <h3 className="font-bold mb-2">Feature Title</h3>
              <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Alias, itaque?</p>
            </div>
          </div>
          <div className="flex gap-3 items-center">
            <div className="p-3 bg-slate-50 border rounded-full">
              <img
                src="https://cdn-icons-png.flaticon.com/512/686/686308.png"
                alt="random"
                className="w-10 h-10 object-fit flex-shrink-0"
              />
            </div>
            <div className="my-4">
              <h3 className="font-bold mb-2">Feature Title</h3>
              <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Alias, itaque?</p>
            </div>
          </div>
          <div className="flex gap-3 items-center">
            <div className="p-3 bg-slate-50 border rounded-full">
              <img
                src="https://cdn-icons-png.flaticon.com/512/686/686308.png"
                alt="random"
                className="w-10 h-10 object-fit flex-shrink-0"
              />
            </div>
            <div className="my-4">
              <h3 className="font-bold mb-2">Feature Title</h3>
              <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Alias, itaque?</p>
            </div>
          </div>
          <div className="flex gap-3 items-center">
            <div className="p-3 bg-slate-50 border rounded-full">
              <img
                src="https://cdn-icons-png.flaticon.com/512/686/686308.png"
                alt="random"
                className="w-10 h-10 object-fit flex-shrink-0"
              />
            </div>
            <div className="my-4">
              <h3 className="font-bold mb-2">Feature Title</h3>
              <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Alias, itaque?</p>
            </div>
          </div>
          <div className="flex gap-3 items-center">
            <div className="p-3 bg-slate-50 border rounded-full">
              <img
                src="https://cdn-icons-png.flaticon.com/512/686/686308.png"
                alt="random"
                className="w-10 h-10 object-fit flex-shrink-0"
              />
            </div>
            <div className="my-4">
              <h3 className="font-bold mb-2">Feature Title</h3>
              <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Alias, itaque?</p>
            </div>
          </div>
          <div className="flex gap-3 items-center">
            <div className="p-3 bg-slate-50 border rounded-full">
              <img
                src="https://cdn-icons-png.flaticon.com/512/686/686308.png"
                alt="random"
                className="w-10 h-10 object-fit flex-shrink-0"
              />
            </div>
            <div className="my-4">
              <h3 className="font-bold mb-2">Feature Title</h3>
              <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Alias, itaque?</p>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
}
