import React from "react";
import { Carousel as Caro } from "nuka-carousel/lib/carousel";

import img1 from "../../assets/carousel/img1.jpg";
import img2 from "../../assets/carousel/img2.jpg";
import img3 from "../../assets/carousel/img3.jpg";
export default function Carousel() {
  return (
    <Caro
      adaptiveHeightAnimation
      autoplay
      autoplayInterval={4000}
      className="carousel "
      wrapAround
      withoutControls
      slideIndex={0}
    >
      <div
        className={`opacity-100 bg-caro1 w-full h-screen bg-fixed bg-center	bg-no-repeat	bg-cover	flex flex-col	 items-center justify-evenly `}
      >
        <h2 className="text-[#e8604c] text-5xl opacity-100	font-semibold	mb-24">
          Jana Tours
          <p className="text-[#fff] mb-36">Where are you heading?</p>
        </h2>
      </div>
      <div
        className={`opacity-100 bg-caro2 w-full h-screen bg-fixed bg-center	bg-no-repeat	bg-cover	flex flex-col items-center justify-evenly `}
      >
        <h2 className="text-[#e8604c] text-5xl opacity-100	font-semibold	mb-24">
          Jana Tours
          <p className="text-[#fff] mb-36">Where are you heading?</p>
        </h2>
      </div>
      <div
        className={`opacity-100 bg-caro3 w-full h-screen bg-fixed bg-center	bg-no-repeat	bg-cover	flex flex-col items-center justify-evenly `}
      >
        <h2 className="text-[#e8604c] text-5xl opacity-100	font-semibold	mb-24">
          Jana Tours
          <p className="text-[#fff] mb-36">Where are you heading?</p>
        </h2>
      </div>
    </Caro>
  );
}
