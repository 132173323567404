import React from "react";
import MainLayout from "../layout/MainLayout";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import axios from "axios";
import { useState } from "react";
import LatestTripCard from "../components/home/inner/LatestTripCard";
import Loader from "../components/common/Loader";

export default function Tours() {
  const { period } = useParams();
  const [prediodFilter, setPrediodFilter] = useState(null);
  const [trips, setTrips] = useState([]);
  const [periods, setPeriods] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    if (!period)
      axios.get("/api/users/home/index").then((res, err) => {
        console.log(res.data.trips.data);
        setTrips(res.data.trips.data);
        setIsLoading(false);
      });
    axios.get("/api/users/home/periods").then((res, err) => {
      setPeriods(res.data);
    });
  }, []);

  useEffect(() => {
    if (prediodFilter || period) {
      setIsLoading(true);
      axios.get(`api/users/home/filterbyperiods/${prediodFilter || period}`).then((res, err) => {
        console.log(res);
        setTrips(res.data.trips.data);
        setIsLoading(false);
      });
    }
  }, [period, prediodFilter]);

  if (isLoading) {
    return (
      <MainLayout>
        <Loader />
      </MainLayout>
    );
  }

  return (
    <MainLayout>
      <main className="container flex flex-col md:flex-row py-5 gap-5">
        <section className="md:w-1/4 border-2 h-96 rounded-lg p-2 overflow-auto">
          <h3>Choose a Period:</h3>
          <a
            href="/tours"
            className={`inline-block hover:text-white hover:bg-[#e8604c] border border-[#e8614c5b]  py-2 px-4 rounded-md w-full my-2 ${
              !period ? "bg-[#e8604c] text-white" : "text-[#e8604c]"
            }`}
          >
            All
          </a>
          {periods.map((el) => (
            <a
              href={`/tours/${el.id}`}
              className={`inline-block hover:text-white hover:bg-[#e8604c] border border-[#e8614c5b]  py-2 px-4 rounded-md w-full my-2 ${
                el.id === parseInt(period) ? "bg-[#e8604c] text-white" : "text-[#e8604c]"
              }`}
              // onClick={() => {
              //   // setPrediodFilter(el.id)
              // }}
            >
              {el.period} Day
            </a>
          ))}
        </section>
        <section className="md:w-3/4 grid grid-cols-1 md:grid-cols-2 gap-5">
          {trips.map((e) => {
            return (
              <LatestTripCard
                image={`https://jana.tours/janatours-jana-tours/public/trips/${e.banner}`}
                title={e.title}
                description={e.description}
                key={e.id}
                date={e.date}
                id={e.id}
              />
            );
          })}
          {trips?.length === 0 && (
            <div className="flex flex-col items-center gap-3">
              <h1 className="text-3xl">No Trips Found</h1>
            </div>
          )}
        </section>
      </main>
    </MainLayout>
  );
}
