import React from "react";
import { Carousel as Caro } from "nuka-carousel/lib/carousel";

import img1 from "../../assets/carousel/img1.jpg";
import img2 from "../../assets/carousel/img2.jpg";
import img3 from "../../assets/carousel/img3.jpg";
export default function Gallery() {
  return (
    <div className="py-10">
      <h2 className="text-center text-3xl mb-5 text-[#e8604c]">Gallery</h2>
      <section className="container flex flex-wrap w-auto justify-between">
        <img src={img1} className="sm:w-1/2 p-2 md:w-1/4 rounded-lg overflow-hidden" />
        <img src={img2} className="sm:w-1/2 p-2 md:w-1/4 rounded-lg overflow-hidden" />
        <img src={img3} className="sm:w-1/2 p-2 md:w-1/4 rounded-lg overflow-hidden" />
        <img src={img1} className="sm:w-1/2 p-2 md:w-1/4 rounded-lg overflow-hidden" />
      </section>
    </div>
  );
}
