import React from "react";
import LatestTripCard from "./inner/LatestTripCard";
import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";

export default function LatestTrips() {
  const [latestTrips, setLatestTrips] = useState([]);
  useEffect(() => {
    axios.get("/api/users/home/index").then((res, err) => {
      console.log(res.data.trips.data);
      setLatestTrips(res.data.trips.data);
    });
  }, []);
  return (
    <div className="py-5">
      <h2 className="text-center text-3xl mb-5 text-[#e8604c]">Latest Trips</h2>
      <section className="container mx-auto sm:columns-2 md:columns-3 xl:columns-3 ">
        {latestTrips.map((e) => {
          return (
            <LatestTripCard
              id={e.id}
              image={`https://jana.tours/janatours-jana-tours/public/trips/${e.banner}`}
              title={e.title.toUpperCase()}
              description={e.description}
              date={e.date}
              key={e.id}
            />
          );
        })}
      </section>
    </div>
  );
}
