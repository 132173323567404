import { Dialog, Transition } from "@headlessui/react";
import axios from "axios";
import { Fragment, useState } from "react";
import { useForm } from "react-hook-form";

export default function BookModal(props) {
  const [notes, setNotes] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    if (isNaN(data.phone)) {
      return setNotes("Phone number must be a number");
    }
    setIsLoading(true);

    const formdata = new FormData();
    formdata.append("name", data.name);
    formdata.append("phone", data.phone);
    formdata.append("email", data.email);
    formdata.append("number_of_adults", data.number_of_adults);
    formdata.append("number_of_childrens", data.number_of_childrens);
    formdata.append("number_of_infants", data.number_of_infants);
    formdata.append("note", data.note);
    formdata.append("trip_id", props.id);
    axios
      .post("/api/users/home/book", formdata)
      .then((res) => {
        setNotes(res.data.message);
        setIsLoading(false);
        res.data.status === 200 && reset();
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <Transition appear show={props.isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={props.closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900 pb-5">
                    {props.title}
                  </Dialog.Title>
                  <div>
                    <form
                      className="flex flex-col gap-5 pb-5 pt-3  max-h-96  overflow-auto"
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <div className="flex flex-col gap-2 w-96">
                        <label htmlFor="name">Name</label>
                        <input
                          type="text"
                          name="name"
                          id="name"
                          className="border py-2 px-1 w-full"
                          {...register("name", { required: true })}
                        />
                        {errors.name && <span className="text-red-500 text-sm">This field is required</span>}
                      </div>
                      <div className="flex flex-col gap-2 w-96">
                        <label htmlFor="phone">Phone</label>
                        <input
                          type="text"
                          name="phone"
                          id="phone"
                          className="border py-2 px-1 w-full"
                          {...register("phone", { required: true })}
                        />
                        {errors.phone && <span className="text-red-500 text-sm">This field is required</span>}
                      </div>
                      <div className="flex flex-col gap-2 w-96">
                        <label htmlFor="email">Email</label>
                        <input
                          type="email"
                          name="email"
                          id="email"
                          className="border py-2 px-1 w-full"
                          {...register("email", { required: true })}
                        />
                        {errors.email && <span className="text-red-500 text-sm">This field is required</span>}
                      </div>
                      <div className="flex flex-col gap-2 w-96">
                        <label htmlFor="number_of_adults">Number of adults</label>
                        <input
                          type="number"
                          name="number_of_adults"
                          id="number_of_adults"
                          className="border py-2 px-1 w-full"
                          value={1}
                          min={1}
                          {...register("number_of_adults", { required: true })}
                        />
                        {errors.number_of_adults && (
                          <span className="text-red-500 text-sm">This field is required</span>
                        )}
                      </div>
                      <div className="flex flex-col gap-2 w-96">
                        <label htmlFor="number_of_childrens">Number of childrens</label>
                        <input
                          type="number"
                          name="number_of_childrens"
                          id="number_of_childrens"
                          className="border py-2 px-1 w-full"
                          value={0}
                          min={0}
                          {...register("number_of_childrens", { required: true })}
                        />
                        {errors.number_of_childrens && (
                          <span className="text-red-500 text-sm">This field is required</span>
                        )}
                      </div>
                      <div className="flex flex-col gap-2 w-96">
                        <label htmlFor="number_of_infants">Number of infants</label>
                        <input
                          type="number"
                          name="number_of_infants"
                          id="number_of_infants"
                          className="border py-2 px-1 w-full"
                          value={0}
                          min={0}
                          {...register("number_of_infants", { required: true })}
                        />
                        {errors.number_of_infants && (
                          <span className="text-red-500 text-sm">This field is required</span>
                        )}
                      </div>

                      <div className="flex flex-col gap-2 w-96">
                        <label htmlFor="note">Note</label>
                        <textarea
                          name="note"
                          id="note"
                          className="border py-2 px-1 w-full"
                          {...register("note")}
                        ></textarea>
                      </div>
                    </form>

                    <div className="flex gap-3 items-center mt-5">
                      <p className="text-sm text-red-500">{notes}</p>
                      <button
                        className="bg-[#e8604c] hover:bg-slate-800 text-white rounded-md px-4 py-2 ml-auto"
                        onClick={handleSubmit(onSubmit)}
                        disabled={isLoading}
                      >
                        {isLoading ? "Loading..." : "Book Now"}
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
